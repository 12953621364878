import React from "react"
import PropTypes from "prop-types"
import useSiteDetailsData from "@modules/site/shared/hooks/useSiteDetailsData"
import BuildDetailsInner from "./DetailsInner"

function BuildDetailsPage({ siteId, buildId, location }) {
  const [siteDetails, { loading, error }] = useSiteDetailsData(siteId)

  return (
    <BuildDetailsInner
      buildId={buildId}
      organizationId={siteDetails?.organization?.id}
      site={siteDetails}
      loading={loading}
      error={error}
      location={location}
    />
  )
}

BuildDetailsPage.propTypes = {
  buildId: PropTypes.string,
  siteId: PropTypes.string,
  organizationId: PropTypes.string,
}

export default BuildDetailsPage
